<template>
  <div class="up-change-pwd">
    <h1 class="up-main-title">Смена пароля</h1>
    <el-form class="up-change-pwd__form">
      <el-form-item>
        <el-input
          v-model="form.password"
          show-password
          placeholder="Новый пароль"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.password2"
          show-password
          placeholder="Повторите пароль"
        />
      </el-form-item>
    </el-form>
    <el-button type="success" @click="changePwdHandler">
      Сменить пароль
    </el-button>
  </div>
</template>

<script>
import showErrorMessage from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "ChangePasswordPage",
  mixins: [showErrorMessage],
  data: () => ({
    form: {
      password: "",
      password2: "",
    },
  }),
  methods: {
    ...mapActions(["setNavigationText", "fetchAccountData"]),
    async changePwdHandler() {
      if (this.form.password !== this.form.password2) {
        this.$message({
          message: "Пароли не совпадают, попробуйте еще раз",
          type: "error",
        });

        return;
      }

      try {
        await this.$axios.post("/auth/profile", {
          password: this.form.password,
        });

        this.$message({
          message: "Пароль успешно обновлен",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Страница смены пароля");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/change-password.scss";
</style>